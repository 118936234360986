import { useEffect } from "react";
import { ScreenProps } from "../../App";
import { CalendarItem } from "../../api/fetchCalender";
import CalendarEntryComponent from "./CalendarEntryComponent";
import {
  useCalenderTodayQuery,
  useCalenderTomorrowQuery,
} from "./queries/useCalenderQueries";
import "./styles/Calendar.sass";
import SantaHat from "../../elements/SantaHat";

export const CalendarScreen: React.FC<ScreenProps> = ({
  duration,
  christmasTime,
  isActive,
}) => {
  const todayData = useCalenderTodayQuery();
  const tomorrowData = useCalenderTomorrowQuery();

  useEffect(() => {
    if (!isActive) {
      todayData.refetch();
      tomorrowData.refetch();
    }
  }, [isActive]);

  if (todayData.isLoading || tomorrowData.isLoading)
    return <div>Loading...</div>;

  if (todayData.isError || tomorrowData.isError) return <div>Error...</div>;

  const renderDayBlock = (items?: CalendarItem[], future = false) => {
    if (items && items.length > 0) {
      return items.map((event, index) => {
        return (
          <CalendarEntryComponent
            event={event}
            future={future}
            index={index}
            christmasTime={christmasTime}
            key={index}
          />
        );
      });
    } else {
      return (
        <div className="LowEntry">
          <div className="Left">&nbsp;</div>
          <div className="Center">
            Sorry, nothing here.
            <p className="Small">But we should really do something about it.</p>
          </div>
          <div className="Right"></div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="CalendarScreen">
        <div className="Tomorrow">
          {christmasTime && <SantaHat />}
          Today
        </div>
        {renderDayBlock(todayData.data)}

        <div className="Tomorrow">
          {christmasTime && <SantaHat />}
          Tomorrow
        </div>
        {renderDayBlock(tomorrowData.data, true)}
      </div>
    </div>
  );
};

export default CalendarScreen;
